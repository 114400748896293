#sponsors {
	position: relative;
	padding-top: 200px;
	margin-top: -500px;
}

.spon-header {
	width: 100vw;
	height: 94px;
	color: #464646;
	font-family: "Raleway";
	font-size: 4vw;
	font-weight: bold;
	text-align: center;
}

.spon-name {
	color: #464646;
	font-family: "Raleway";
	font-size: 3vw;
	font-weight: 600;
	text-align: center;
}

.image img {
	width: 30vw;
	margin-right: 1vw;
}
.spon-media-div {
	display: table;
	width: 100vw;
	text-align: center;
}

.spon-partners-div{
	width: 100vw;
	text-align: center;
}

.image {
	display: table-cell;
	vertical-align: middle;
}

.spon-media-div2 {
	display: table;
	width: 100vw;
	text-align: center;
}

.spon-first-row {
	display: flex;
	margin-top: 15vh;
	text-align: center;
	width: 100vw;
}
.org {
	width: 50vw;
}
.strat {
	width: 50vw;
}

@media (max-width: 500px) {
	#sponsors {
		position: relative;
		padding-top: 450px;
	}
	.spon-header {
		width: 100vw;
		height: 50px;
		font-size: 45px;
		margin-left: 0;
		margin-top: -300px;
	}
	.spon-name {
		font-size: 30px;
	}

	.spon-media-div {
		display: flex;
		flex-direction: column;
	}
	.spon-media-div2 {
		display: flex;
		flex-direction: column;
	}
	.image {
		margin-top: 5vh;
	}
	.image img {
		width: 60vw;
	}

	.spon-first-row {
		flex-direction: column;
		margin-top: 5vh;
	}
	.strat {
		margin-top: 10vh;
		width: 100vw;
	}
	.org {
		width: 100vw;
	}
}
