* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@media (max-width: 700px) {
    .section-title {
        font-size: 50px;
        padding: 5vh 0 5vh 0;
    }
}

@media (min-width: 700px) {
    .section-title {
        font-size: 80px;
        padding: 5vh 0 5vh 0;
    }
}
