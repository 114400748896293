@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.wave-logo {
    border-radius: 30px;
    margin-top: 130px;
    text-align: center;
}
.wave-logo img {
    border-radius: 30px;
    height: 60vh;
}

/* Waves styling and waves animations */

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(../../../Assets/wave.png);
    background-size: 1000px 100px;
}
.wave1 {
    animation: animate 30s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
}

.wave2 {
    animation: animate2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
}

.wave3 {
    animation: animate2 20s linear infinite;
    z-index: 998;
    opacity: 0.3;
    animation-delay: -2s;
    bottom: 15px;
}

@keyframes animate {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}

@keyframes animate2 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: -1000px;
    }
}

/*Responsiveness*/
@media (min-width: 1400px) {
    .wave-item {
        display: grid;
        grid-template-areas: " left-container right-container";
        grid-template-columns: 1fr 1fr;
        padding-top: 0;
        margin-top: 2vh;
    }

    .wave-container {
        position: relative;
        width: 100%;
        height: 90vh;
        background: #efb822;
        overflow: hidden;
        color: #464646;
        font-family: "Raleway", sans-serif;
        padding-bottom:  50px;

    }
}

@media (max-width: 1400px) {
    .wave-item {
        display: grid;
        grid-template-areas:
            " left-container "
            " right-container";
        grid-template-columns: 1fr 1fr;
        margin-top: 1vh;
    }
    .wave-container {
        position: relative;
        width: 100%;
        height: 80vh;
        background: #efb822;
        overflow: hidden;
        color: #464646;
        font-family: "Raleway", sans-serif;
        padding-bottom:  100px;

    }

}

@media (min-width: 700px) {
    .wave-text-small {
        font-size: 23px;
    }
    .wave-text-big {
        font-size: 60px;
    }

    .wave-text {
        padding: 5% 0 0 20%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
@media (max-width: 700px) {
    .wave-item {
        display: flex;
        flex-wrap: wrap;
        margin-top: 120px;
    }
    .wave-text-small {
        font-size: 20px;
        text-align: center;

    }
    .wave-text-big {
        font-size: 50px;
        text-align: center;

    }

    .wave-text {
        width: 100vw;
    }

    .wave-logo{
        width: 100vw;
    }
    .wave-logo img{
        height:50vw;
        justify-content: center;
        align-items: center;
        margin-top: -100px;
    }
}
