#about-conference{
	position: relative;
	padding-top: 100px;
	margin-top: -100px;
}
.header-conf {
	text-align: right;
	font-size: 3vw;
	font-weight: bold;
	color: #464646;
	font-family: "Raleway";
	margin-top: 80px;
	margin-right: 5vw;
	float: right;
}

.text-conf {
	font-size: 1.1vw;
	font-weight: 600;
	color: #464646;
	font-family: "Raleway";
	margin-top: 50px;
	text-align: justify;
	float: right;
	width: 50vw;
	margin-left: 5vw;
	margin-right: 5vw;
	letter-spacing: 1.2px;
	line-height: 1.8vw;
}

.grey-circle {
	margin-left: 95px;
	margin-top: -400px;
	width: 436px;
	height: 425px;
	background-color: rgba(206, 203, 216, 0.35);
	border-radius: 100%;
}

.slider {
	margin-left: 188px;
	margin-top: 80px;
	width: 442px;
	height: 442px;
	overflow: hidden;
}

.slides {
	display: flex;
}

.slides input {
	display: none;
}

.slide {
	width: 100%;
	transition: 1s;
}

.slide img {
	width: 442px;
	height: 442px;
	border-radius: 100%;
}

.navigation-manual {
	position: absolute;
	width: 442px;
	display: flex;
	justify-content: center;
	margin-top: -90px;
}

.manual-btn {
	width: 30px;
	height: 30px;
	border: 2px solid #ffffff;
	border-radius: 100%;
	cursor: pointer;
	transition: 0.5s;
	margin: 10px;
}

.manual-btn:hover {
	background-color: #ffffff;
}

#radio1:checked ~ .first {
	margin-left: 0;
}

#radio2:checked ~ .first {
	margin-left: -100%;
}

#radio3:checked ~ .first {
	margin-left: -200%;
}

#radio4:checked ~ .first {
	margin-left: -300%;
}

#radio5:checked ~ .first {
	margin-left: -400%;
}

.navigation-auto {
	position: absolute;
	width: 642px;
	display: flex;
	justify-content: center;
	margin-top: 555px;
}

.navigation-auto div {
	width: 30px;
	height: 30px;
	border: 2px solid white;
	border-radius: 100%;
	cursor: pointer;
	transition: 0.5s;
	margin: 10px;
}

#radio1:checked ~ .navigation-auto .auto-btn1 {
	background-color: #ffffff;
	transform: scale(1.5);
}

#radio2:checked ~ .navigation-auto .auto-btn2 {
	background-color: #ffffff;
	transform: scale(1.5);
}

#radio3:checked ~ .navigation-auto .auto-btn3 {
	background-color: #ffffff;
	transform: scale(1.5);
}

#radio4:checked ~ .navigation-auto .auto-btn4 {
	background-color: #ffffff;
	transform: scale(1.5);
}

#radio5:checked ~ .navigation-auto .auto-btn5 {
	background-color: #ffffff;
	transform: scale(1.5);
}

.about-full-screen {
	display: flex;
}
.about-full1 {
}

.about-full2 {
}

@media (max-width: 630px) {
	.about-full-screen {
		display: flex;
		flex-wrap: wrap-reverse;
	}
	.about-full1 {
		margin-top: -50px;
	}
	.slider {
		width: 300px;
		height: 300px;
		margin-left: 30px;
	}
	.slide img {
		width: 300px;
		height: 300px;
	}

	.header-conf {
		text-align: center;
		font-size: 45px;
		width: 100vw;
		margin-top: 80px;
	}
	.text-conf {
		margin-left: 2vw;
		text-align: center;
		width: 90vw;
		float: left;
		font-size: 4vw;
		line-height: 5vw;
		margin-top: 40px;
	}

	.navigation-manual {
		width: 300px;
	}

	.navigation-auto {
		width: 300px;
		margin-top: 214px;
	}
	.navigation-auto div {
		width: 20px;
		height: 20px;
	}
	.manual-btn {
		width: 20px;
		height: 20px;
	}
	.grey-circle {
		margin-left: 10px;
		margin-top: -270px;
		width: 300px;
		height: 300px;
	}
}

@media (min-width: 631px) and (max-width: 830px) {
	.about-full-screen {
		display: flex;
		flex-wrap: wrap-reverse;
	}

	.slider {
		width: 500px;
		height: 500px;
		margin-left: 100px;
	}
	.slide img {
		width: 500px;
		height: 500px;
	}
	.about-full-screen {
		display: none;
	}

	.navigation-manual {
		width: 500px;
	}

	.navigation-auto {
		width: 500px;
		margin-top: 413px;
	}
	.navigation-auto div {
		width: 20px;
		height: 20px;
	}
	.manual-btn {
		width: 20px;
		height: 20px;
	}
	.grey-circle {
		margin-left: 20px;
		margin-top: -440px;
		width: 500px;
		height: 500px;
	}
}

@media (min-width: 831px) and (max-width: 1300px) {
	#about-conference {
		position: relative;
		padding-top: 100px;
	}
	.header-conf{
		text-align: center;
		width: 100vw;
	}

	.about-full-screen {
		display: flex;
		flex-wrap: wrap-reverse;
		justify-content: center;
	}

	.about-mobile-screen {
		display: none;
	}
}

