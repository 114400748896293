.wave-container2 {
	position: relative;
	width: 100%;
	height: 90vh;
	background: #3b1ac9;
	margin-top: 80px;
	overflow: hidden;
	color: white;
	font-family: "Raleway", sans-serif;
}

a{
	text-decoration: none;
	color:white;
}

.wave-text {
	padding-left: 2vw;
	text-align: left;
	margin-top: 0px;
}

.wave-text1 {
	font-size:2vh;
	color: #f4cd57;
	font-weight: 600;
}

.wave-text2 {
	padding-top: 27px;
	color: #ededed;
	font-size: 5vh;
	font-weight: normal;
	flex-wrap: wrap;
	height: 70px;
}

.wave-text3 {
	font-size: 12vh;
	font-weight: normal;
	color: #ededed;
}

.wave-text4 {
	font-size: 12vh;
	font-weight: bold;
	color: #efb822;
	margin-left: -70px;
}

.wave-text5 {
	font-size: 2.2vh;
	font-weight: normal;
	color: #ededed;
	padding-top: 27px;
	width:40vw;
}

.wave-button {
	background-color: #dc7241;
	border-radius: 38px;
	border: 1px solid #707070;
	color: white;
	font-weight: medium;
	font-size: 2.2vh;
	padding: 2vh 10vw 2vh 10vw;
	margin-top: 2vh;
	cursor: pointer;
}

.wave-button:hover {
	background-color: #d75f28;
}

.wave-img img{
	margin: auto;
	margin-top:5vh;
	height: 50vh;
}

.wave-session-full-screen {
	display: flex;
}

.wave-content {
	display: flex;
}

/* Waves styling and waves animations */

.wave {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: url(../../../Assets/wave.png);
	background-size: 1000px 100px;
	z-index: 1;

}
.wave1 {
	animation: animate 30s linear infinite;
	opacity: 1;
	animation-delay: 0s;
	bottom: 0;
}

.wave2 {
	animation: animate2 15s linear infinite;
	opacity: 0.5;
	animation-delay: -5s;
	bottom: 10px;
}

.wave3 {
	animation: animate2 20s linear infinite;
	opacity: 0.3;
	animation-delay: -2s;
	bottom: 15px;
}

@keyframes animate {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: 1000px;
	}
}

@keyframes animate2 {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: -1000px;
	}
}



@media (max-width: 1130px) {
	.wave-text {
		padding-left: 30px;
		padding-top: 80px;
	}

	.wave-text1 {
		font-size: 2vh;
	}

	.wave-text2 {
		font-size:4vh;
	}
	.wave-text3 {
		font-size: 12vh;
		margin-top: -30px;
	}
	.wave-text4 {
		font-size: 12vh;
		margin-left: 1vw;

	}
	.wave-text5 {
		width:50vw;
		font-size: 2.2vh;
	}

	.wave-button {
		margin-top: 0px;
	
	}

	.wave-session-full-screen {
		display: none;
	}
}

@media (min-width: 1131px) and (max-width: 1400px) {
	.wave-session-full-screen {
		flex-wrap: wrap;
	}
	.wave-text5 {
		width: 50vw;
	}
	.wave-session-mobile-screen {
		display: none;
	}
	.wave-text4{
		margin-left: 30px;
	}
}

@media (min-width: 1401px) {
	.wave-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas: "wave-text wave-img";
	}
	.wave-text {
		padding-left: 50px;
	}
	.wave-text3 {
		float: left;
	}

	.wave-text4 {
		margin-left: 2vw;
		float: right;
	}
	.wave-session-mobile-screen {
		display: none;
	}
}

@media (min-width: 1701px){
	.wave-session-mobile-screen {
		display: none;
	}
}

@media (min-width: 1900px){
	.wave-session-mobile-screen {
		display: none;
	}

	.wave-text4{
		margin-left: 2vw;
		float:left;
	}
}

@media (max-width:600px){
	.wave-img{
		display: none;
	}

	.wave-text{
		margin-top: -40px;
	}

	.wave-text5{
		width:80vw;
		font-size: 2vh;
	}

	.wave-button{
		margin-top: -50px;
	}
}