#programme {
    display: flex;
    flex-direction: column;
    padding: 10vh 0 1vh 0;
    position: relative;
        padding-top: 150px;
}

.programme-title {
    text-align: center;
}

@media (max-width: 700px) {
    #programme{
       position: relative;
        padding-top: 100px; 
    }
    .date-container-left {
        width: 5em;
        background-color: #efb822;
        display: inline-block;
        font-size: 30px;
        border-radius: 0 20vh 20vh 0;
        padding: 2vh;
        margin-bottom: 2vh;
    }

    .date-container-right {
        width: 5em;
        background-color: #efb822;
        display: inline-block;
        font-size: 30px;
        border-radius: 20vh 0 0 20vh;
        padding: 2vh;
        margin-left: auto;
        margin-bottom: 2vh;
    }
}

@media (min-width: 700px) {
    .date-container-left {
        width: 5em;
        background-color: #efb822;
        display: inline-block;
        font-size: 60px;
        border-radius: 0 20vh 20vh 0;
        padding: 4vh;
        margin-bottom: 5vh;
    }

    .date-container-right {
        width: 5em;
        background-color: #efb822;
        display: inline-block;
        font-size: 60px;
        border-radius: 20vh 0 0 20vh;
        padding: 4vh;
        margin-left: auto;
        margin-bottom: 5vh;
    }
}
