.footer-wave-container {
	position: relative;
	width: 100%;
	background: #3b1ac9;
	margin-top: 109px;
	color: white;
	font-family: "Raleway";
	padding-bottom: 5vh;
	z-index: 0;
}

.footer-maps {
	width: 40vw;
	height: 40vh;
	margin-top: 200px;
	margin-left: 10vw;
	overflow: hidden;
	border-radius: 50px;
}

.footer-text1 {
	margin-top: 230px;
	margin-left: 10vw;
	height: 141px;
	text-align: left;
	font-size: 1.5vw;
	font-weight: 600;
}

.footer-text2 {
	margin-top: 20px;
	margin-left: 10vw;
	height: 35px;
	text-align: left;
	font-size: 1.5vw;
	font-weight: medium;
	font-style: italic;
}

.footer-text3 {
	height: 47px;
	margin-left: 10vw;
	text-align: left;
	font-size: 1.5vw;
	font-weight: 600;
}

.footer-wave {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: url(../../Assets/wave.png);
	background-size: 1000px 100px;
	transform: rotate(180deg);
}
.footer-wave1 {
	animation: animate 30s linear infinite;
	z-index: 1000;
	opacity: 1;
	animation-delay: 0s;
	bottom: 0;
}

.footer-wave2 {
	animation: animate2 15s linear infinite;
	z-index: 999;
	opacity: 0.5;
	animation-delay: -5s;
	bottom: 10px;
}

.footer-wave3 {
	animation: animate2 20s linear infinite;
	z-index: 998;
	opacity: 0.3;
	animation-delay: -2s;
	bottom: 15px;
}

@keyframes animate {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: 1000px;
	}
}

@keyframes animate2 {
	0% {
		background-position-x: 0;
	}
	100% {
		background-position-x: -1000px;
	}
}

.footer-content {
	display: flex;
	margin-right: 2vw;
}

@media (max-width: 1000px) {
	.footer-content {
		flex-direction: column;
		flex-wrap: wrap;
	}

	.footer-maps {
		width: 80vw;
	}
	.footer-text1 {
		margin-top: 5vh;
		font-size: 20px;
	}
	.footer-text2 {
		margin-top: -2vh;
	}
	.footer-text3 {
		font-size: 20px;
	}
}
